import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const unsouvenir = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Un souvenir" />
    <h3 className='underline-title'>Un souvenir</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Aziza la sénégalaise<br />
        À moi-même j'étais étranger avant ta venue<br />
        Je tremblais de vivre sans toi Aziza<br />
        Ton ombre noire blanchit ma nuit cordiale<br />
        Je tremblote maintenant de ton ombre irradiante<br />
        Je frissonne donc de mort et de vie <br />
        Quel cercle! plus que vicieux!<br />
        Je suis captif de mon propre coeur <br />
        Sois ma geôlière dans cette maison de force <br />
        Souviens-toi Aziza:<br />
        Ta visite vespérale illuminait ma chambre d'étudiant<br />
        Nos rencontres ensoleillaient Paris d'hiver<br />
        Nos longues stations fleurissaient Boul' Mich'<br />
        À toi ma pensée Aziza du sénégal <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default unsouvenir
